import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialog } from '../DataDialog/DataDialog';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { Helmet } from 'react-helmet-async';
import { AUTH_URL_PREFIX } from '../../flags';
import PageInnerMain from './PageInnerMain';
import { PagePreviewEmbed } from '../PageBlaze/PagePreviewEmbed';
import { PageEngineApp } from '../../../index_page_blaze';
import { sitesTrashDialog } from '../PageBlaze/utils';
import CommonDialogs from './CommonDialogs';
import useTokenCookie from '../../hooks/useTokenCookie';


function PageAppInner() {
  useTokenCookie();
  return (
    <>
      <Helmet defer={false}>
        <title>Page Blaze</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route exact path="/preview/" render={() => <ErrorBoundary>
          <PagePreviewEmbed />
        </ErrorBoundary>} />
        {/*this route is used only in local development, on production, firebase maps /p/ to the app engine cloud run's instance*/}
        <Route path={AUTH_URL_PREFIX}
          render={() => <ErrorBoundary>
            <PageEngineApp />
          </ErrorBoundary>}
        />
        <Route component={PageInnerMain} />
      </Switch>

      <AuthDialog />
      <CommonDialogs />
      {sitesTrashDialog()}
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default PageAppInner;
